import React from 'react';
import Icon, { Size } from '@leafygreen-ui/icon';
import { palette } from '@leafygreen-ui/palette';

import { IndexIcon } from 'baas-ui/common/svgs';
import { passThroughProps } from 'baas-ui/common/utils/util';

export enum TestSelector {
  CompleteIcon = 'complete-icon',
  IncompleteIcon = 'incomplete-icon',
}

export interface Props {
  index: number;
  isComplete: boolean;
  className?: string;
  size?: Size;
  disabled?: boolean;
}

export const ChecklistIcon = ({ index, isComplete, className, size, disabled, ...rest }: Props) => {
  const passedProps = passThroughProps(rest);

  return isComplete ? (
    <Icon
      data-testid={TestSelector.CompleteIcon}
      glyph="CheckmarkWithCircle"
      size={size}
      {...passedProps}
      className={className}
      fill={palette.green.dark1}
    />
  ) : (
    <IndexIcon
      data-testid={TestSelector.IncompleteIcon}
      {...passedProps}
      disabled={disabled}
      className={className}
      index={index}
    />
  );
};

export default ChecklistIcon;
