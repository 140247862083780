import React from 'react';

const AppsEmptyStateSVG = () => (
  <svg width="298" height="198" viewBox="0 0 298 198" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M205.56 138.242L46.0968 138.242L30.6313 53.3809L190.095 53.3809L205.56 138.242Z"
      fill="white"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.0968 138.242L42.2787 144.8L26.8066 59.9388L30.6314 53.3809L46.0968 138.242Z"
      fill="white"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.2788 144.799L201.735 144.799L205.56 138.241L46.0969 138.241L42.2788 144.799Z"
      fill="white"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M186.183 115.99L178.368 70.4037C177.769 66.9154 174.45 64.085 170.958 64.085L97.329 64.085C93.8368 64.085 91.4887 66.9154 92.0873 70.4037L99.9032 115.99C100.502 119.478 103.821 122.309 107.313 122.309L180.942 122.309C184.434 122.309 186.782 119.478 186.183 115.99Z"
      fill="black"
    />
    <path
      d="M237.289 13.2891L134.326 13.2891C131.413 13.2891 129.051 15.648 129.051 18.558V84.6222C129.051 87.5322 131.413 89.8911 134.326 89.8911L237.289 89.8911C240.202 89.8911 242.564 87.5322 242.564 84.6222V18.558C242.564 15.648 240.202 13.2891 237.289 13.2891Z"
      fill="#00684A"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M242.63 21.8135L129.011 21.8135" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M133.581 19.594C134.599 19.594 135.424 18.77 135.424 17.7536C135.424 16.7371 134.599 15.9131 133.581 15.9131C132.563 15.9131 131.739 16.7371 131.739 17.7536C131.739 18.77 132.563 19.594 133.581 19.594Z"
      fill="white"
    />
    <path
      d="M139.135 19.594C140.153 19.594 140.978 18.77 140.978 17.7536C140.978 16.7371 140.153 15.9131 139.135 15.9131C138.118 15.9131 137.293 16.7371 137.293 17.7536C137.293 18.77 138.118 19.594 139.135 19.594Z"
      fill="white"
    />
    <path
      d="M144.696 19.594C145.714 19.594 146.539 18.77 146.539 17.7536C146.539 16.7371 145.714 15.9131 144.696 15.9131C143.678 15.9131 142.854 16.7371 142.854 17.7536C142.854 18.77 143.678 19.594 144.696 19.594Z"
      fill="white"
    />
    <path
      d="M91.6017 180.187L103.628 144.8H99.6104L87.584 180.187H91.6017Z"
      fill="black"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.444 144.8L129.417 180.187H91.6018L103.628 144.8H141.444Z"
      fill="#00ED64"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M101.247 151.81L138.942 152.168L141.444 144.8H103.628L101.247 151.81Z"
      fill="black"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M232.061 34.1719L167.459 34.1719V39.4142L232.061 39.4142V34.1719Z" fill="#00ED64" />
    <path d="M271.193 34.1719L234.628 34.1719V39.4142L271.193 39.4142V34.1719Z" fill="#00ED64" />
    <path d="M271.193 45.2285H253.393V50.4709H271.193V45.2285Z" fill="#00ED64" />
    <path d="M250.819 45.2285L167.459 45.2285V50.4709L250.819 50.4709V45.2285Z" fill="#00ED64" />
    <path d="M226.939 56.291H209.139V61.5334H226.939V56.291Z" fill="#00ED64" />
    <path d="M206.824 56.291L167.459 56.291V61.5334L206.824 61.5334V56.291Z" fill="#00ED64" />
    <path d="M262.28 67.3467L167.459 67.3467V72.589L262.28 72.589V67.3467Z" fill="#00ED64" />
    <path
      d="M185.804 180.188H85.0564V184.712H185.804V180.188Z"
      fill="#00ED64"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AppsEmptyStateSVG;
