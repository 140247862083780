import { BaasAdminClient, PartialApp, UserProfile } from 'admin-sdk';

import { MetricsState } from './app/metrics-card/types';
import { ReadonlyAPIKeysState, ReadonlyAuthState, ReadonlySecurityState } from './auth/types';
import { FeatureFlag } from './common/featureSettings';
import { DeployStoreState } from './deploy/types';
import { DomainRedirectProviderState } from './domain-redirect-provider/reducers';
import { DataAPIState } from './endpoints/data-api/reducers';
import { TriggersState } from './events/types';
import { DependenciesState } from './functions/dependencies/types';
import { FunctionsState } from './functions/types';
import { GraphQLState } from './graphql/reducers';
import { AppsStoreState } from './home/types';
import { IncomingWebhookState } from './incomingwebhooks/reducers';
import { LogsState } from './logs/types';
import { MeasurementsState } from './measurements/types';
import { NavigationState } from './nav/types';
import { PushState } from './push/reducers';
import { SchemasState } from './schema/reducers';
import { SdkState } from './sdks/reducers';
import { RealmLanguage } from './sdks/types';
import { ServiceState } from './services/types';
import { SyncState } from './sync/reducers';
import { RawAppSyncData, RawClientSchema, RawSyncProgress } from './sync/types';
import { UsersState } from './users/reducers';
import { UserSettingsState } from './usersettings/types';
import { ValuesState } from './values/reducers';
import { RouterState } from './reducers';

export interface AdminAppClient {
  export(): Promise<void>;
  sync(): {
    clientSchemas(): {
      get(language: RealmLanguage, filter?: string[]): Promise<RawClientSchema[]>;
    };
    data({ service_id }: { service_id?: string }): Promise<RawAppSyncData>;
    progress(): Promise<RawSyncProgress>;
  };
}

export interface BaseState {
  adminClient?: BaasAdminClient;
  settings: {
    [key: string]: any;
    adminUrl?: string;
    cloudUIBaseUrl?: string;
    product?: string;
    showCloudNav?: boolean;
  };
  userProfile: UserProfile;
  betaFeatures?: string[];
  firstAppSave?: boolean;
  firstDraftSave?: boolean;
}
/* eslint-enable */

// TODO update the following as we add more types to reducers
// This is used by selectors.ts
export interface State {
  [k: string]: any;
  app: Readonly<{
    app: PartialApp;
    error: string;
    loadingApp: boolean;
    loadingFeatureFlags: boolean;
    enabledFeatureFlags?: FeatureFlag[];
    isFullscreen: boolean;
    resourceNotFoundError: string;
    setEnvironmentData: {
      error?: string;
      isLoading: boolean;
    };
    isDeploymentMigrating: boolean;
  }>;
  auth: {
    auth: ReadonlyAuthState;
    apiKeys: ReadonlyAPIKeysState;
    security: ReadonlySecurityState;
  };
  base: Readonly<BaseState>;
  users: UsersState;
  userSettings: UserSettingsState;
  dependencies: DependenciesState;
  deployment: DeployStoreState;
  events: TriggersState;
  functions: FunctionsState;
  graphql: GraphQLState;
  home: AppsStoreState;
  incomingWebhooks: IncomingWebhookState;
  logs: LogsState;
  measurements: MeasurementsState;
  metrics: MetricsState;
  navigation: NavigationState;
  push: PushState;
  router: RouterState;
  schemas: SchemasState;
  sdks: SdkState;
  service: ServiceState;
  sync: SyncState;
  values: ValuesState;
  dataAPI: DataAPIState;
  domainRedirect: DomainRedirectProviderState;
}

export type RootState = Readonly<State>;

export interface BaseRequestPayload {
  groupId: string;
  appId: string;
}

export type VoidEventFunc = (e?: React.ChangeEvent<HTMLInputElement>) => void;

export interface App {
  id: string;
  groupId: string;
}

export enum BaasEnvironment {
  Prod = 'prod',
  Staging = 'staging',
  Dev = 'dev',
  QA = 'qa',
  Local = 'local',
  Test = 'test',
}

export enum ServicesBaasHostname {
  Prod = 'services.cloud.mongodb.com',
  Staging = 'services.cloud-stage.mongodb.com',
  QA = 'services.cloud-qa.mongodb.com',
  Dev = 'services.cloud-dev.mongodb.com',
}

export enum RealmBaasHostname {
  Prod = 'realm.mongodb.com',
  Staging = 'realm-staging.mongodb.com',
  QA = 'realm-qa.mongodb.com',
  Dev = 'realm-dev.mongodb.com',
}
