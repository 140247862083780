import { History, TransitionPromptHook } from 'history';
import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';

import navReducer from 'baas-ui/nav/reducers';
import { UserProfile } from 'admin-sdk';

import metricsReducer from './app/metrics-card/reducers';
import appReducer from './app/reducers';
import authReducer from './auth/reducers';
import deploymentReducer from './deploy/reducers';
import domainRedirectProviderReducer from './domain-redirect-provider/reducers';
import dataAPIReducer from './endpoints/data-api/reducers';
import eventsReducer from './events/reducers';
import dependencyReducer from './functions/dependencies/reducers';
import functionsReducer from './functions/reducers';
import graphqlReducer from './graphql/reducers';
import hostingReducer from './hosting/reducers';
import incomingWebhooksReducer from './incomingwebhooks/reducers';
import logsReducer from './logs/reducers';
import measurementsReducer from './measurements/reducers';
import pushReducer from './push/reducers';
import schemasReducer from './schema/reducers';
import sdksReducer from './sdks/reducers';
import sessionReducer from './session/reducers';
import syncReducer from './sync/reducers';
import usersReducer from './users/reducers';
import userSettingsReducer from './usersettings/reducers';
import valuesReducer from './values/reducers';
import {
  blockNavigation,
  clearUserProfile,
  getUserProfileActions,
  setFirstAppSaveAction,
  setFirstDraftSaveAction,
  setHistory,
  unblockNavigation,
  unsetFirstAppSaveAction,
  unsetFirstDraftSaveAction,
} from './actions';
import { alertReducer } from './alert';
import home from './home';
import services from './services';
import { BaseState } from './types';

export interface RouterState {
  history?: History;
  unblock?(): void;
}

const routerDefaultState = {
  history: undefined,
  unblock: undefined,
};

const routerReducer = createReducer<RouterState>({}, routerDefaultState);
routerReducer.on(setHistory, (state, payload: History) => ({ ...state, history: payload }));
routerReducer.on(blockNavigation, (state, blockFn: TransitionPromptHook = () => {}) => {
  // `history` will throw a 'A history supports only one prompt at a time' error if `block`
  // is called multiple times (without being unblocked). To avoid this, simply do nothing
  // if we encounter this case.
  //
  // Alternatively, we could call unblock and then block again with the new `blockFn`
  if (typeof state.unblock === 'function') {
    return state;
  }

  return {
    ...state,
    unblock: state.history?.block(blockFn),
  };
});
routerReducer.on(unblockNavigation, (state) => {
  if (typeof state.unblock !== 'function') {
    return state;
  }

  state.unblock();

  return {
    ...state,
    unblock: undefined,
  };
});

const baseDefaultState = {
  adminClient: undefined,
  settings: {},
  userProfile: new UserProfile(),
  betaFeatures: undefined,
  firstAppSave: false,
  firstDraftSave: false,
};

const baseReducer = createReducer<BaseState>({}, baseDefaultState);
baseReducer.on(getUserProfileActions.rcv, (state, payload) => ({
  ...state,
  userProfile: payload.payload,
}));
baseReducer.on(clearUserProfile, (state) => ({ ...state, userProfile: new UserProfile() }));
baseReducer.on(setFirstAppSaveAction, (state) => ({
  ...state,
  firstAppSave: true,
}));
baseReducer.on(unsetFirstAppSaveAction, (state) => ({
  ...state,
  firstAppSave: false,
}));
baseReducer.on(setFirstDraftSaveAction, (state) => ({
  ...state,
  firstDraftSave: true,
}));
baseReducer.on(unsetFirstDraftSaveAction, (state) => ({
  ...state,
  firstDraftSave: false,
}));

const allReducers = combineReducers({
  alerts: alertReducer,
  app: appReducer,
  auth: authReducer,
  base: baseReducer,
  userSettings: userSettingsReducer,
  dependencies: dependencyReducer,
  deployment: deploymentReducer,
  events: eventsReducer,
  dataAPI: dataAPIReducer,
  functions: functionsReducer,
  graphql: graphqlReducer,
  home: home.reducer,
  hosting: hostingReducer,
  incomingWebhooks: incomingWebhooksReducer,
  logs: logsReducer,
  measurements: measurementsReducer,
  metrics: metricsReducer,
  navigation: navReducer,
  push: pushReducer,
  router: routerReducer,
  schemas: schemasReducer,
  sdks: sdksReducer,
  service: services.reducer,
  session: sessionReducer,
  sync: syncReducer,
  users: usersReducer,
  values: valuesReducer,
  domainRedirect: domainRedirectProviderReducer,
});

const rootReducer = (state, action) => {
  if (action.type.includes('root/reset app')) {
    const { app, base, home: homeState, router, session, deployment, measurements, domainRedirect } = state;
    state = { app, base, home: homeState, router, session, deployment, measurements, domainRedirect };
  }
  if (action.type.includes('root/clear all app state')) {
    const { base, home: homeState, router, session, navigation, domainRedirect } = state;
    state = { base, home: homeState, router, session, navigation, domainRedirect };
  }
  return allReducers(state, action);
};

export default rootReducer;
