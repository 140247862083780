import React from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import loadable from 'baas-ui/common/components/loadable';
import { Title } from 'baas-ui/common/components/title';
import { FeatureFlag } from 'baas-ui/common/featureSettings';
import NotFound from 'baas-ui/home/not-found';
import { BreadcrumbsItem, CloudBreadcrumbs, ErrorBoundaryRoute } from 'baas-ui/nav';
import { featureSettings } from 'baas-ui/stitch_ui';
import { rootUrl } from 'baas-ui/urls';

import AddService from './AddService';
import ServicesList from './ServicesList';

const EditService = loadable(() =>
  import(/* webpackChunkName: 'EditService' */ '../../services/components/EditService')
);

const Services = (props) => {
  const { match, app } = props;

  const servicesRootUrl = rootUrl.groups().group(app.groupId).apps().app(app.id).services();

  const thirdPartyServicesAllowed = featureSettings.get(FeatureFlag.ThirdPartyServicesAllowed);

  return (
    <>
      <Title>Services</Title>
      <Switch>
        <ErrorBoundaryRoute
          exact
          path={servicesRootUrl.list()}
          render={(routeProps) =>
            thirdPartyServicesAllowed ? <ServicesList {...props} {...routeProps} /> : <NotFound />
          }
        />
        <ErrorBoundaryRoute
          path={servicesRootUrl.new()}
          render={(routeProps) =>
            thirdPartyServicesAllowed ? (
              <div>
                <Title>Create Service</Title>
                <AddService key="new" {...props} {...routeProps} />
                <CloudBreadcrumbs />
                <BreadcrumbsItem to={match.url}>Services</BreadcrumbsItem>
              </div>
            ) : (
              <NotFound />
            )
          }
        />
        <ErrorBoundaryRoute
          path={servicesRootUrl.service(':svcId').rules().list()}
          render={(routeProps) =>
            thirdPartyServicesAllowed ? (
              <EditService key="edit" editing rootServicesUrl={match.url} {...props} {...routeProps} />
            ) : (
              <NotFound />
            )
          }
        />
        <ErrorBoundaryRoute
          path={servicesRootUrl.service(':svcId').get()}
          render={(routeProps) => (
            <EditService key="edit" editing rootServicesUrl={match.url} {...props} {...routeProps} />
          )}
        />
      </Switch>
    </>
  );
};

Services.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

export default Services;
