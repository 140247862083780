import React, { useEffect, useState } from 'react';
import Box from '@leafygreen-ui/box';
import Button, { Variant as ButtonVariant } from '@leafygreen-ui/button';
import EditIcon from '@leafygreen-ui/icon/dist/Edit';
import IconButton from '@leafygreen-ui/icon-button';
import { palette } from '@leafygreen-ui/palette';
import TextInput from '@leafygreen-ui/text-input';
import { Link } from '@leafygreen-ui/typography';
import { Body, Description, Disclaimer } from '@leafygreen-ui/typography';

import DataSourceSelect from 'baas-ui/common/components/data-source-select';
import { DataSourceSelectOptionType, DataSourceType } from 'baas-ui/common/components/data-source-select';
import DocLink from 'baas-ui/common/components/doc-link';
import ExpandableStepCard from 'baas-ui/common/components/expandable-step-card';
import { LoadingWrapper } from 'baas-ui/common/components/loading-wrapper';
import ProviderRegionSelect from 'baas-ui/common/components/provider-region-select';
import { PRODUCT_SELF_HOSTED } from 'baas-ui/common/constants';
import { docLinks } from 'baas-ui/common/links';
import { CreateAppProps } from 'baas-ui/home/common/withCreateApp';
import ClusterSelectionRadio from 'baas-ui/home/create-app/cluster-selection-radio';
import { TemplateAppNames } from 'baas-ui/home/create-app/constants';
import ApplicationRegionSelection from 'baas-ui/home/create-app/form/application-region-selection';
import {
  dataSourceOptionSupportsSync,
  getCardIndices,
  processDataSourceOpts,
  StepCardIdentifier,
} from 'baas-ui/home/create-app/form/util';
import { TemplateIdentifier } from 'baas-ui/home/create-app/types';
import { DataSourceLinkMethod, DefaultDataSourceServiceName } from 'baas-ui/home/types';
import { validateAppName } from 'baas-ui/home/validation';
import { DeploymentModel } from 'admin-sdk';

import './config-section.less';

export enum TestSelector {
  ConfigFormSection = 'config-form-section',
  TemplatesFormRow = 'templates-formrow',
  DataSourcesFormRow = 'data-sources-formrow',
  AppNameFormRow = 'app-name-formrow',
  DeploymentFormRow = 'deployment-formrow',
  TemplateStarterMenu = 'template-starter-menu',
  TemplateName = 'template-name',
  DataSourceName = 'data-source-name',
  AppName = 'app-name',
  AppNameInput = 'app-name-input',
  AppNameInputButton = 'app-name-input-button',
  DataSourceLinkMethodRadio = 'data-source-link-method-radio',
  DataSources = 'data-source-select',
  DataSourcesDisclaimer = 'data-source-select-disclaimer',
  DataSourcesDisclaimerLink = 'data-source-select-disclaimer-link',
  OutdatedVersionDisclaimer = 'outdated-version-disclaimer',
  DefaultDataSourceNameDisclaimer = 'default-data-source-name-disclaimer',
  DataSourceSaveButton = 'data-source-save-button',
}

const baseClassName = 'create-app-config-section';

interface PublicProps {
  onChangeDeploymentModel(): void;
  onConfirmProviderRegion(): void;
  onChangeProviderRegion(): void;
  onCancelProviderRegion(): void;
}

export type Props = CreateAppProps & PublicProps;

export const ConfigSection = ({
  isLoadingApps,
  defaultAppName,
  appName,
  setAppName,
  appNameError,
  setAppNameError,
  templateId,
  deploymentModel,
  setDeploymentModel,
  defaultProviderRegion,
  providerRegion,
  setProviderRegion,
  isCreatingApp,
  product,
  loadingDataSources,
  m0Version,
  clusterOpts,
  dataLakeOpts,
  flexClusterOpts,
  onlineArchiveOpts,
  serverlessInstanceOpts,
  atlasClustersUrl,
  dataSourceLinkMethod,
  setDataSourceLinkMethod,
  selectedDataSource,
  setSelectedDataSource,
  onChangeDeploymentModel,
  onConfirmProviderRegion,
  onChangeProviderRegion,
  onCancelProviderRegion,
}: Props) => {
  const [defaultDataSourceName, setDefaultDataSourceName] = React.useState<string>('');
  const [clusterWithOutdatedVersionSelected, setClusterWithOutdatedVersionSelected] = React.useState<boolean>(false);
  const [showRegionRecommendationInfo, setShowRegionRecommendationInfo] = useState(true);

  const templateSelected = templateId !== TemplateIdentifier.Default;
  const isSelfHosted = product === PRODUCT_SELF_HOSTED;

  const {
    hasM0Cluster,
    enabledClusterOpts,
    enabledDataLakeOpts,
    enabledFlexClusterOpts,
    enabledOnlineArchiveOpts,
    enabledServerlessInstanceOpts,
    numEnabledDataSources,
  } = processDataSourceOpts({
    clusterOpts,
    dataLakeOpts,
    flexClusterOpts,
    onlineArchiveOpts,
    serverlessInstanceOpts,
    templateId,
  });

  React.useEffect(() => {
    setDefaultDataSourceName(
      selectedDataSource?.type === DataSourceType.DATA_FEDERATION
        ? DefaultDataSourceServiceName.DataLake
        : DefaultDataSourceServiceName.Cluster
    );
    setClusterWithOutdatedVersionSelected(
      selectedDataSource?.type === DataSourceType.CLUSTER && !dataSourceOptionSupportsSync(selectedDataSource)
    );
  }, [selectedDataSource]);

  // card states
  const [appNameCardOpen, setAppNameCardOpen] = useState(false);
  const [dataSourceCardOpen, setDataSourceCardOpen] = useState(false);
  const [changeProviderRegionOpen, setChangeProviderRegionOpen] = useState(false);
  const [showRegionSelect, setShowRegionSelect] = useState(false);

  const cardIndices = getCardIndices(templateSelected, isSelfHosted);

  useEffect(() => {
    setShowRegionSelect(changeProviderRegionOpen);
  }, [changeProviderRegionOpen]);

  return (
    <div data-testid={TestSelector.ConfigFormSection} className={baseClassName}>
      {templateSelected && (
        <ExpandableStepCard
          data-cy="template-app-form-field"
          data-test-selector={TestSelector.TemplatesFormRow}
          stepNumber={cardIndices[StepCardIdentifier.Template]}
          title="Select a Template"
          description={
            <Description>
              Using App Services reduces the code you need to write. Get started quicker with Templates.
            </Description>
          }
          isComplete
        >
          <div className={`${baseClassName}-closed-content`}>
            <Body data-test-selector={TestSelector.TemplateName} data-cy="template-name">
              {TemplateAppNames[templateId]}
            </Body>
          </div>
        </ExpandableStepCard>
      )}
      {!isSelfHosted && (
        <ExpandableStepCard
          data-test-selector={TestSelector.DataSourcesFormRow}
          data-testid={TestSelector.DataSourcesFormRow}
          data-cy="data-source-form-field"
          stepNumber={cardIndices[StepCardIdentifier.DataSource]}
          title="Link your Data Source"
          description={
            <Description>
              {`Template Starter Applications let you build on top of data in a MongoDB Atlas `}
              <Link target="_blank" href={atlasClustersUrl}>
                cluster.
              </Link>
            </Description>
          }
          open={dataSourceCardOpen}
          onClick={() => setDataSourceCardOpen(!dataSourceCardOpen)}
        >
          {dataSourceCardOpen ? (
            <LoadingWrapper isLoading={loadingDataSources}>
              <>
                <ClusterSelectionRadio
                  setDataSourceLinkMethod={setDataSourceLinkMethod}
                  dataSourceLinkMethod={dataSourceLinkMethod}
                  createClusterDisabled={hasM0Cluster}
                  useExistingDisabled={!numEnabledDataSources}
                  data-test-selector={TestSelector.DataSourceLinkMethodRadio}
                  m0Version={m0Version}
                />
                {clusterWithOutdatedVersionSelected && (
                  <Body data-test-selector={TestSelector.OutdatedVersionDisclaimer}>
                    You will need to run MongoDB 4.4+ or&nbsp;
                    <Link target="_blank" href={`${atlasClustersUrl}/pathSelector`}>
                      build a new Atlas cluster
                    </Link>
                    &nbsp;to use Device Sync.
                  </Body>
                )}
                <Box className={`${baseClassName}-data-source-select`} data-testid={TestSelector.DataSources}>
                  <DataSourceSelect
                    id="createAppDataSourceSelect"
                    data-test-selector={TestSelector.DataSources}
                    value={selectedDataSource}
                    onChange={(opt: DataSourceSelectOptionType) => setSelectedDataSource(opt)}
                    isDisabled={
                      isCreatingApp ||
                      (clusterOpts.length === 0 &&
                        flexClusterOpts.length === 0 &&
                        dataLakeOpts.length === 0 &&
                        serverlessInstanceOpts.length === 0)
                    }
                    options={[
                      ...clusterOpts,
                      ...dataLakeOpts,
                      ...flexClusterOpts,
                      ...onlineArchiveOpts,
                      ...serverlessInstanceOpts,
                    ]}
                    enabledOptions={[
                      ...enabledClusterOpts,
                      ...enabledDataLakeOpts,
                      ...enabledFlexClusterOpts,
                      ...enabledOnlineArchiveOpts,
                      ...enabledServerlessInstanceOpts,
                    ]}
                    selectedTemplate={templateId}
                  />
                  {!loadingDataSources && selectedDataSource && (
                    <Disclaimer
                      className={`${baseClassName}-data-source-select-disclaimer`}
                      data-test-selector={TestSelector.DefaultDataSourceNameDisclaimer}
                    >
                      When referring to this data source within your application you will need to use&nbsp;
                      <b>{defaultDataSourceName}</b> as the service name.
                    </Disclaimer>
                  )}
                  {!numEnabledDataSources && hasM0Cluster && (
                    <Disclaimer
                      className={`${baseClassName}-data-source-select-warning`}
                      style={templateSelected ? { color: palette.red.base } : { color: palette.yellow.dark2 }}
                      data-test-selector={TestSelector.DataSourcesDisclaimer}
                      data-cy={TestSelector.DataSourcesDisclaimer}
                    >
                      No eligible data sources are available at this time. Please create a new data source in{' '}
                      <Link
                        className={`${baseClassName}-data-source-select-warning-link`}
                        target="_blank"
                        data-test-selector={TestSelector.DataSourcesDisclaimerLink}
                        href={atlasClustersUrl}
                      >
                        {`Atlas${templateSelected ? '.' : ''}`}
                      </Link>
                      {!templateSelected && ', or create the app now and link a data source later.'}
                    </Disclaimer>
                  )}
                </Box>
              </>
              <Button
                data-cy="save-data-source-button"
                data-testid={TestSelector.DataSourceSaveButton}
                variant={ButtonVariant.Primary}
                onClick={() => setDataSourceCardOpen(false)}
                className={`${baseClassName}-form-field-save-button`}
              >
                Save
              </Button>
            </LoadingWrapper>
          ) : (
            <div className={`${baseClassName}-closed-content`}>
              <Body data-cy="data-source" data-test-selector={TestSelector.DataSourceName}>
                {dataSourceLinkMethod === DataSourceLinkMethod.UseExisting &&
                  (selectedDataSource?.label || 'No Data Source Selected')}
                {dataSourceLinkMethod === DataSourceLinkMethod.CreateCluster && 'Create New M0 Atlas Cluster'}
              </Body>
              <IconButton aria-label="edit data source">
                <EditIcon />
              </IconButton>
            </div>
          )}
        </ExpandableStepCard>
      )}
      <ExpandableStepCard
        data-test-selector={TestSelector.AppNameFormRow}
        data-cy="app-name-form-field"
        stepNumber={cardIndices[StepCardIdentifier.Name]}
        title="Name your Application"
        description={<Description>This name will be used internally and cannot be changed later.</Description>}
        open={appNameCardOpen}
        onClick={() => setAppNameCardOpen(!appNameCardOpen)}
      >
        {appNameCardOpen ? (
          <>
            <TextInput
              data-test-selector={TestSelector.AppNameInput}
              data-cy="create-app-name-field"
              aria-labelledby="app-title"
              placeholder={defaultAppName}
              state={appNameError ? 'error' : 'none'}
              value={appName}
              onChange={({ target: { value } }) => {
                setAppName(value);
                setAppNameError(validateAppName(value));
              }}
              errorMessage={appNameError}
              disabled={isCreatingApp || isLoadingApps}
            />
            <Button
              data-test-selector={TestSelector.AppNameInputButton}
              data-cy="save-app-name-button"
              variant={ButtonVariant.Primary}
              onClick={() => setAppNameCardOpen(false)}
              className={`${baseClassName}-form-field-save-button`}
              disabled={!!appNameError || isCreatingApp}
            >
              Save
            </Button>
          </>
        ) : (
          <div className={`${baseClassName}-closed-content`}>
            <Body data-test-selector={TestSelector.AppName} data-cy="app-name">
              {appName}
            </Body>
            <IconButton aria-label="edit app name">
              <EditIcon />
            </IconButton>
          </div>
        )}
      </ExpandableStepCard>
      <ExpandableStepCard
        data-test-selector={TestSelector.DeploymentFormRow}
        data-testid={TestSelector.DeploymentFormRow}
        data-cy="deployment-form-field"
        stepNumber={cardIndices[StepCardIdentifier.Deployment]}
        title="App Deployment Model"
        open={changeProviderRegionOpen}
        onClick={() => {
          if (changeProviderRegionOpen) {
            onConfirmProviderRegion();
          }

          setChangeProviderRegionOpen(!changeProviderRegionOpen);
        }}
        description={
          <Description>
            You can change this later in your Application Settings.{' '}
            <DocLink href={docLinks.General.DeploymentModels}>View Docs</DocLink>
          </Description>
        }
      >
        {changeProviderRegionOpen ? (
          <ApplicationRegionSelection
            deploymentModel={deploymentModel}
            providerRegion={providerRegion}
            setProviderRegion={setProviderRegion}
            showRegionRecommendationInfo={showRegionRecommendationInfo}
            setShowRegionRecommendationInfo={setShowRegionRecommendationInfo}
            disabled={isCreatingApp}
            onChangeDeploymentModel={(depModel: DeploymentModel) => {
              setDeploymentModel(depModel);

              // Reset to the closest provider region so that users know the
              // suggested provider region
              setProviderRegion(defaultProviderRegion);
              setShowRegionRecommendationInfo(true);
              onChangeDeploymentModel();
            }}
            onConfirm={onConfirmProviderRegion}
            onChange={onChangeProviderRegion}
            onCancel={onCancelProviderRegion}
            showRegionSelect={showRegionSelect}
          />
        ) : (
          <ProviderRegionSelect
            deploymentModel={deploymentModel}
            setProviderRegion={setProviderRegion}
            providerRegion={providerRegion}
            onChange={() => {
              setChangeProviderRegionOpen(true);
              setShowRegionSelect(true);
              onChangeProviderRegion();
            }}
            onConfirm={() => {
              setShowRegionRecommendationInfo(false);
              setShowRegionSelect(false);
              onConfirmProviderRegion();
            }}
            onCancel={() => {
              setShowRegionSelect(false);
              onCancelProviderRegion();
            }}
            showRegionRecommendationInfo={showRegionRecommendationInfo}
            disabled={isCreatingApp}
            showDeploymentModel
            showRegionSelect={showRegionSelect}
          />
        )}
      </ExpandableStepCard>
    </div>
  );
};

export default ConfigSection;
