import React from 'react';
import { connect } from 'react-redux';
import { MongoDBLogoMark } from '@leafygreen-ui/logo';
import { UserMenu } from '@lg-private/mongo-nav';
import { AccountInterface } from '@lg-private/mongo-nav/src/types';

import { PRODUCT_SELF_HOSTED } from 'baas-ui/common/constants';
import { Breadcrumbs, BreadcrumbsItem, TopNav } from 'baas-ui/nav';
import { getNavigationState, getSettingsState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';
import { rootUrl } from 'baas-ui/urls';

import './create-app-header.less';

const baseClassname = 'create-app-header';
const topClassname = `${baseClassname}-top`;

export enum TestSelector {
  Header = 'create-app-header',
}

interface ReduxStateProps {
  accountUIBaseUrl: string;
  cloudUIBaseUrl: string;
  chartsUIBaseUrl: string;
  baseUrl: string;
  account: AccountInterface;
  isSelfHosted: boolean;
}

export type Props = ReduxStateProps;

export function CreateAppHeader({
  accountUIBaseUrl,
  cloudUIBaseUrl,
  chartsUIBaseUrl,
  baseUrl,
  account,
  isSelfHosted,
}: Props) {
  return (
    <div data-testid={TestSelector.Header} className={baseClassname}>
      <TopNav isHidden />
      <div className={topClassname}>
        <MongoDBLogoMark className={`${topClassname}-logo`} height={30} />
        <UserMenu
          account={account}
          activePlatform={'cloud'}
          hosts={{ account: accountUIBaseUrl, cloud: cloudUIBaseUrl, charts: chartsUIBaseUrl, realm: baseUrl }}
          {...(isSelfHosted ? { urls: { userMenu: { logout: rootUrl.logout() } } } : {})}
        />
      </div>
      <BreadcrumbsItem>Create an App Service</BreadcrumbsItem>
      <Breadcrumbs />
      <div className={`${baseClassname}-text`}>{`Create an App Service`}</div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  const { accountUIBaseUrl, cloudUIBaseUrl, chartsUIBaseUrl, baseUrl, product } = getSettingsState(state);
  const { account } = getNavigationState(state);

  return {
    accountUIBaseUrl,
    cloudUIBaseUrl,
    chartsUIBaseUrl,
    baseUrl,
    account,
    isSelfHosted: product === PRODUCT_SELF_HOSTED,
  };
};

export default connect(mapStateToProps)(CreateAppHeader);
