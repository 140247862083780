import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import Button, { Variant } from '@leafygreen-ui/button';
import Modal, { Footer } from '@leafygreen-ui/modal';
import { Body, H3, Link } from '@leafygreen-ui/typography';

import { docLinks } from 'baas-ui/common/links';
import { activeGuideKey, isFirstAppCreatedOnGroup } from 'baas-ui/common/local-storage-keys';
import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getHomeState } from 'baas-ui/selectors';
import { track } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import { replaceBaseUrl, withQueryParams } from 'baas-ui/urls';

import * as actions from './actions';
import { Domain } from './types';

const StyledModal = styled(Modal)`
  z-index: ${ZIndex.Modal};
`;

const StyledModalFooter = styled(Footer)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 24px 0px 0px;
`;

const StyledCancelButton = styled(Button)`
  margin-right: 10px;
`;

const StyledHeader = styled(H3)`
  margin-bottom: 5px;
`;

export enum TestSelector {
  DomainRedirectModal = 'domain-redirect-modal',
  CancelButton = 'cancel-button',
  ContinueButton = 'continue-button',
  NewDomainLink = 'new-domain-link',
  DocsLink = 'docs-link',
}

export interface OwnProps {
  oldDomain: Domain;
  open: boolean;
  setOpen(open: boolean): void;
}

interface StateProps {
  groupAppIds: string[];
  groupId: string;
}

interface DispatchProps {
  rejectDomainRedirect(): void;
}

type Props = StateProps & DispatchProps & OwnProps;

const getLocalStorageValuesToMigrate = (groupId: string, groupAppIds: string[]) => {
  const localStorageKeysToMigrate = [isFirstAppCreatedOnGroup(groupId)];

  if (groupAppIds.length) {
    localStorageKeysToMigrate.push(...groupAppIds.reduce((accList, id) => [...accList, activeGuideKey(id)], []));
  }

  return localStorageKeysToMigrate.reduce((accObject, key) => {
    const value = window.localStorage.getItem(key);
    if (value && value !== 'undefined' && value !== '{}') {
      return { ...accObject, [key]: value };
    }
    return accObject;
  }, {});
};

const DomainRedirectModal = ({ oldDomain, open, setOpen, rejectDomainRedirect, groupAppIds, groupId }: Props) => {
  const onCancel = () => {
    rejectDomainRedirect();
    track('APPLICATION.DOMAIN_REDIRECT_REJECTED', { oldDomain });
    setOpen(false);
  };

  const onRedirect = () => {
    track('APPLICATION.DOMAIN_REDIRECT_ISSUED', { oldDomain });
    setOpen(false);

    const servicesUrlWithQueryParams = withQueryParams(
      replaceBaseUrl(window.settings.adminUrl, window.location.href),
      getLocalStorageValuesToMigrate(groupId, groupAppIds),
      true
    );

    window.open(servicesUrlWithQueryParams, '_self');
  };

  return (
    <StyledModal data-testid={TestSelector.DomainRedirectModal} open={open} setOpen={setOpen}>
      <StyledHeader>Redirect Notice: Domain Deprecation</StyledHeader>
      <Body>
        You are being redirected to{' '}
        <Link
          data-testid={TestSelector.NewDomainLink}
          href={replaceBaseUrl(window.settings.adminUrl, window.location.href)}
          hideExternalIcon
        >
          services.cloud.mongodb.com
        </Link>{' '}
        as realm.mongodb.com is deprecated. While realm.mongodb.com still continues to work, we recommend updating your
        bookmarks and issuing API requests to services.cloud.mongodb.com for a seamless transition in the future.{' '}
        <Link data-testid={TestSelector.DocsLink} href={docLinks.General.DomainMigration.LearnMore}>
          Learn more
        </Link>
      </Body>
      <StyledModalFooter>
        <StyledCancelButton data-testid={TestSelector.CancelButton} onClick={onCancel}>
          Cancel
        </StyledCancelButton>
        <Button data-testid={TestSelector.ContinueButton} variant={Variant.Primary} onClick={onRedirect}>
          Continue
        </Button>
      </StyledModalFooter>
    </StyledModal>
  );
};

const mapStateToProps = (state: RootState) => ({
  groupAppIds: getHomeState(state).apps.map((app) => app.id),
  groupId: getHomeState(state).groupId,
});

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  rejectDomainRedirect: () => dispatch(actions.rejectDomainRedirect()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DomainRedirectModal);
