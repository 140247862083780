import { ThemePreference } from '@mongodb-js/darkreader';

import { cloudNavKey, themePreferenceKey } from './common/local-storage-keys';

interface CloudStartupPreferences {
  themePreference: ThemePreference;
  showCloudNav: boolean;
}

// defaultStartupPreference is used when running locally or if the fetch to cloud user appStartup values fails
const defaultStartupPreference: CloudStartupPreferences = {
  themePreference: ThemePreference.LIGHT,
  showCloudNav: false,
};

// getCloudStartupPreferences fetches the user appStartup preferences when applicable
export const getCloudStartupPreferences = (cloudUIBaseUrl: string): Promise<CloudStartupPreferences> => {
  if (!cloudUIBaseUrl) {
    return Promise.resolve(defaultStartupPreference);
  }

  return fetch(`${window.settings.cloudUIBaseUrl}/user/appStartup`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  })
    .then((response) => response.json())
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return defaultStartupPreference;
    });
};

// parseThemePreference determines the user theme preference based on their cloud startup preferences
export const parseThemePreference = (preferences: CloudStartupPreferences): ThemePreference => {
  const themePreferenceOverride = window.localStorage.getItem(themePreferenceKey());
  if (themePreferenceOverride) {
    // assume override is correctly formatted
    return themePreferenceOverride as ThemePreference;
  }
  return preferences.themePreference;
};

// parseCloudNavPreference determines if a user should see the CloudNav based on their cloud startup preferences
// or a local storage override
export const parseCloudNavPreference = (preferences: CloudStartupPreferences): boolean => {
  const cloudNavOverride = window.localStorage.getItem(cloudNavKey());
  return cloudNavOverride ? cloudNavOverride.toLowerCase() === 'true' : preferences.showCloudNav;
};
