import React from 'react';
import Box from '@leafygreen-ui/box';
import Card from '@leafygreen-ui/card';
import Icon, { Size } from '@leafygreen-ui/icon';
import { Subtitle } from '@leafygreen-ui/typography';

import ChecklistIcon from 'baas-ui/common/components/checklist-icon';

import './expandable-step-card.less';

export enum TestSelector {
  StepNumber = 'step-number',
  Title = 'title',
  DescriptionText = 'description',
  DescriptionContent = 'descriptionContent',
  Chevron = 'chevron',
  EditButton = 'edit-button',
  WrapperCard = 'card',
  Children = 'children',
}

export interface Props {
  stepNumber: number;
  title: string;
  open?: boolean;
  description?: React.ReactNode;
  onClick?(e: React.MouseEvent<Element>): void;
  children: React.ReactNode;
  isComplete?: boolean;
}

const baseClassName = 'expandable-step-card';
const ExpandableStepCard = ({
  stepNumber,
  title,
  description,
  onClick,
  open = false,
  children,
  isComplete = false,
  ...rest
}: Props) => {
  const cardProps: React.ComponentProps<typeof Card> & { 'data-test-selector': string } = {
    ...rest,
    className: baseClassName,
    'data-test-selector': TestSelector.WrapperCard,
  };
  if (!isComplete) {
    cardProps.onClick = (e) => {
      if (!open) {
        onClick?.(e);
      }
    };
  }

  return (
    <Card {...cardProps}>
      <Box>
        <ChecklistIcon
          data-test-selector={TestSelector.StepNumber}
          index={stepNumber}
          size={Size.XLarge}
          isComplete={isComplete}
        />
      </Box>
      <div className={`${baseClassName}-content`}>
        <Subtitle data-test-selector={TestSelector.Title}>{title}</Subtitle>
        {description && <div data-test-selector={TestSelector.DescriptionContent}>{description}</div>}
        <div className={`${baseClassName}-content-children`}>
          <div className={`${baseClassName}-content-children-wrapper`} data-test-selector={TestSelector.Children}>
            {children}
          </div>
        </div>
      </div>
      {!isComplete && (
        <Box>
          <Icon
            data-test-selector={TestSelector.Chevron}
            glyph={open ? 'ChevronDown' : 'ChevronRight'}
            onClick={onClick}
          />
        </Box>
      )}
    </Card>
  );
};

export default ExpandableStepCard;
