import React from 'react';
import classNames from 'classnames';
import { ENVIRONMENT } from 'heliotrope';
import { Iterable } from 'immutable';

import { FeatureFlag } from './common/featureSettings';
import { featureSettings } from './stitch_ui';
import { BaasEnvironment } from './types';

// stopPropagation returns an event handler that stops propagation (if the event is defined)
// before calling f.
export const stopPropagation =
  (f: (arg?: any) => void = () => {}) =>
  (event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    return f();
  };

export const existy = (val) => {
  // Intentional `!=` instead of `!==`,
  // will evaluate to false for both `undefined`
  // and `null` because javascript. Useful for
  // checking whether or not a value is defined,
  // treating `null` and `undefined` as the only
  // "falsy" options.
  return val != null;
};

export const pluralize = (count: number, singular: string, plural: string) => (count === 1 ? singular : plural);

/**
 * Creates a simple stateless functional component that activates a given set of CSS classes
 * when certain corresponding props are specified.
 *
 * @param {Object} propsMapping - A mapping of prop names to CSS class names.
 * @param {string} displayName - The display name for the React component
 * @param {string=} className - A classname that is *always* applied to instances of the component.
 */
export const basicComponent = (propsMapping: {}, displayName: string, className = '') => {
  /* eslint-disable react/prop-types */
  const result = (props) => {
    const componentClassName = classNames(
      className,
      props.className,
      Object.keys(propsMapping).reduce((obj, k) => ({ ...obj, [propsMapping[k]]: props[k] }), {})
    );

    const newProps = { ...props, className: componentClassName };
    Object.keys(propsMapping).forEach((k) => delete newProps[k]);
    return <div {...newProps} />;
  };
  /* eslint-enable react/prop-types */
  result.displayName = displayName;
  return result;
};

// Returns an event handler function which calls the given argument (a function)
// if the event is an "enter" keypress.
// The optional second argument is a function that will get called if the
// user presses Escape.
export const enterPressedCaller =
  (func: () => any, escFunc?: () => any): React.KeyboardEventHandler<HTMLInputElement> =>
  (e) => {
    if (e.key === 'Enter') {
      func();
    }
    if (typeof escFunc === 'function' && e.key === 'Escape') {
      escFunc();
    }
  };

export const isImmutable = (value) => Iterable.isIterable(value);

// Makes a DOM Event of the given name. Use this in place of the
// normal Event constructor for browser compatibility.
export function makeDOMEvent(name: string, eventInit = { bubbles: false, cancelable: false }) {
  if (typeof Event === 'function') {
    return new Event(name, eventInit);
  }

  // IE11 support
  const event = document.createEvent('Event');
  event.initEvent(name, eventInit.bubbles, eventInit.cancelable);
  return event;
}

export const getHeliotropeEnvironment = (environmentDesc: string) => {
  switch (environmentDesc as BaasEnvironment) {
    case BaasEnvironment.Prod:
      return ENVIRONMENT.PROD;
    case BaasEnvironment.QA:
      return ENVIRONMENT.QA;
    case BaasEnvironment.Dev:
      return ENVIRONMENT.DEV;
    case BaasEnvironment.Local:
      return ENVIRONMENT.LOCAL;
    default:
      return ENVIRONMENT.LOCAL;
  }
};

export const isGraphQLFeatureAllowed = () => {
  return featureSettings.get(FeatureFlag.GraphQLAllowed);
};

export const isHostingFeaturedAllowed = () => {
  return featureSettings.get(FeatureFlag.HostingAllowed);
};

export const isEndpointsAndDataApiAllowed = () => {
  return featureSettings.get(FeatureFlag.EndpointsDataAPIAllowed);
};

export const isSyncEOL = () => {
  return featureSettings.get(FeatureFlag.SyncEOL);
};

export const isSyncFeatureAllowed = () => {
  return featureSettings.get(FeatureFlag.SyncAllowed) || !featureSettings.get(FeatureFlag.SyncEOL);
};

export const isEdgeFeatureAllowed = () => {
  return !featureSettings.get(FeatureFlag.SyncEOL);
};

// if the app-level flag is true, allow third party services
export const isThirdPartyServicesAllowed = () => {
  return featureSettings.get(FeatureFlag.ThirdPartyServicesAllowed);
};
